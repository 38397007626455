import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Row } from '../components/helpers';
import Toddler from '../../src/Icons/Toddler';
import Bouncer from '../../src/Icons/Bouncer';
import ChangingTable from '../../src/Icons/ChangingTable';
import Crib from '../../src/Icons/Crib';
import CribSize from '../../src/Icons/CribSize';
import Glider from '../../src/Icons/Glider';
import Swaddler from '../../src/Icons/Swaddler';
import ToddlerBed from '../../src/Icons/ToddlerBed';
import ToddlerBedding from '../../src/Icons/ToddlerBedding';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "icons"
    }}>{`Icons`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import IconName from '@mfrm/mfcl/Icons/[Icon Name]'
`}</code></pre>
    <Playground __position={0} __code={'<Row>\n  <Toddler width={30} height={30} />\n  <Bouncer width={30} height={30} />\n  <ChangingTable width={30} height={30} />\n  <Crib width={30} height={30} />\n  <Glider width={30} height={30} />\n  <Swaddler width={30} height={30} />\n  <CribSize width={30} height={30} />\n  <ToddlerBed width={30} height={30} />\n  <ToddlerBedding width={30} height={30} />\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Row,
      Toddler,
      Bouncer,
      ChangingTable,
      Crib,
      CribSize,
      Glider,
      Swaddler,
      ToddlerBed,
      ToddlerBedding,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <Toddler width={30} height={30} mdxType="Toddler" />
    <Bouncer width={30} height={30} mdxType="Bouncer" />
    <ChangingTable width={30} height={30} mdxType="ChangingTable" />
    <Crib width={30} height={30} mdxType="Crib" />
    <Glider width={30} height={30} mdxType="Glider" />
    <Swaddler width={30} height={30} mdxType="Swaddler" />
    <CribSize width={30} height={30} mdxType="CribSize" />
    <ToddlerBed width={30} height={30} mdxType="ToddlerBed" />
    <ToddlerBedding width={30} height={30} mdxType="ToddlerBedding" />
  </Row>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      